import moment from 'moment';
import dateTimeConfig from '@/shared/lib/config/dateTimeConfig';

/**
 * Format to date time
 * @param date
 * @param format
 */
export const formatDateTime = (date: string | number | Date, format = dateTimeConfig.dateTimeFormat) => {
  return moment(date).format(format);
};

/**
 * Format to date
 * @param date
 * @param format
 */
export const formatDate = (date: string | number | Date, format = dateTimeConfig.dateFormat) => {
  return moment(date).format(format);
};

/**
 * Parse string date to timestamp
 * @param date
 */
export const parseDateString = (date: string): number => {
  return new Date(date).getTime();
};

/**
 * Add full day (23:59:59) to timestamp
 * @param timestamp
 */
export const addFullDayToTimestamp = (timestamp: number) => {
  const dateWithFullDay: Date = new Date(timestamp);

  dateWithFullDay.setHours(23);
  dateWithFullDay.setMinutes(59);
  dateWithFullDay.setSeconds(59);

  return dateWithFullDay.getTime();
};

/**
 * Format string date
 * @param dateString
 */
export const formatStringDate = (dateString: string) => {
  const timestamp: number = parseDateString(dateString);
  return formatDateTime(timestamp);
};

/**
 * Check if given date is after today
 * @param dateString
 */
export const isAfterToday = (dateString: string): boolean => {
  const timestamp: number = parseDateString(dateString);
  return moment(timestamp).isAfter(new Date());
};
